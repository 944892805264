import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { Assessment, AssessmentResult, AssessmentResultSummary } from "../../tsUtils/assessmentTypes";
import { RootState } from "../../store/store";

export interface AssessmentState extends EntityState<Assessment> {
  loading: boolean;
  loaded: Boolean;
  error: any | null;
  selected_assessment: Assessment & { results: AssessmentResultSummary[]} | null;
}

const AssessmentAdapter = createEntityAdapter<Assessment>({});

export const TestInitialState: AssessmentState = AssessmentAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  selected_assessment: null,
});

export const AssessmentSlice = createSlice({
  name: "assessments",
  initialState: TestInitialState,
  reducers: {
    assessmentLoading(state) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    assessmentLoaded(state) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    assessmentFail(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getAssessmentsSuccess(state, action) {
      AssessmentAdapter.upsertMany(state, action.payload);
      state.loading = false;
      state.loaded = true;
    },
    updateSelectedAssessment(state, action) {
      state.selected_assessment = action.payload;
    },
    createAssessmentSuccess(state, action) {
      AssessmentAdapter.upsertOne(state, action.payload);
    },
    deleteAssessmentSuccess(state, action) {
      // remove by id
      AssessmentAdapter.removeOne(state, action.payload);
    },
    updateAssessmentSuccess(state, action) {
      const results = state.selected_assessment?.results;
      state.selected_assessment = action.payload;
      if (results && state.selected_assessment) {
        state.selected_assessment.results = results;
      }
    },
    getAssessmentDetailsSuccess(state, action) {
      state.selected_assessment = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getAssessmentResultsSuccess(state, action) {
      const selectedAssessment = state.entities[action.payload.assessment_id]
      if (!selectedAssessment) {
        return;
      }
      state.selected_assessment = {
        ...selectedAssessment,
        results: action.payload.results,
      };
      state.loading = false;
      state.loaded = true;
    },
    createAssessmentResultSuccess(state, action) {
      if (!state.selected_assessment) {
        return;
      }
      state.selected_assessment.results.push(action.payload);
      state.loading = false;
      state.loaded = true;
    },
    deleteAssessmentResultSuccess(state, action) {
      if (!state.selected_assessment) {
        return;
      }

      state.selected_assessment.results = state.selected_assessment.results.filter(result => result.id !== action.payload);
      state.loading = false;
      state.loaded = true;
    },
    updateAssessmentResultSuccess(state, action) {
      if (!state.selected_assessment) {
        return;
      }
      const resultIndex = state.selected_assessment.results.findIndex(result => result.id === action.payload.id);
      state.selected_assessment.results[resultIndex] = {
        ...state.selected_assessment.results[resultIndex],
        results: action.payload.results,
        comment: action.payload.comment,
      };
      state.loading = false;
      state.loaded = true;
    },
  },
});

export const AssessmentSelectors = AssessmentAdapter.getSelectors<RootState>(
  (state) => state.assessments
);


export const {
  assessmentLoading,
  assessmentLoaded,
  assessmentFail,
  getAssessmentsSuccess,
  getAssessmentDetailsSuccess,
  updateSelectedAssessment,
  createAssessmentSuccess,
  deleteAssessmentSuccess,
  updateAssessmentSuccess,
  getAssessmentResultsSuccess,
  createAssessmentResultSuccess,
  deleteAssessmentResultSuccess,
  updateAssessmentResultSuccess,
} = AssessmentSlice.actions;
