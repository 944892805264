import React, { ReactElement, useCallback, useEffect } from 'react';
import {
  Definition,
  input_type,
  InputGroup,
} from '../../../tsUtils';

import { useFieldArray, Controller, useForm } from 'react-hook-form';
import {
  MenuItem,
  Grid,
  Button,
  TextField,
  Avatar,
  Typography,
  debounce,
  Popover,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import '../../ClientStyle/ClientSets.scss';
import store from '../../../store/store';
import { exerciseCardActions } from '../../../+store/exerciseCards/actionTypes';
import { ClientInputsInterface } from '../../../tsUtils/clientInputsTypes';
import { useDispatch, useSelector } from 'react-redux';
import { StyledInput } from '../../../common/StyledInput';
import { StyledSelect } from '../../../common/StyledSelect';
import { selectinput_types } from '../../../+store/definitions/inputs/reducers';
import { Info, InfoOutlined } from '@mui/icons-material';

interface Props {
  cardIndex: number;
  inputs: ClientInputsInterface;
  prescribed: InputGroup;
  inputOptions: { [key: string]: Definition };
}

const styles = {
  divContainer: (numberInputs: number) => { return {
    margin: '0px 5px' ,
    display: 'grid',
    gridTemplateColumns: `30px repeat(${numberInputs}, 1fr)`,
    gap: '2px',
    width: 'calc(100% - 15px)',
  }},
  cardInputs: {
    display: 'flex',
    flexDirection: 'column',
  },
  divItem: {
    display: 'grid',
    gridTemplateRows: 'repeat(auto-fill, 32px)',
    gap: '2px',
  },
  avatar: {
    width: '24px',
    height: '24px',
    margin: '4px 2px',
    fontSize: 'small',
    backgroundColor: '#15998E',
  },
  select: {
    fontSize: 'small',
  },
  deleteIcon: {
    cursor: 'pointer',
    height: '32px',
  },
  button: {
    height: '32px',
  },
  spacer: {
    height: '25px',
  },
  deleteSpacer: {
    height: '32px',
  },
} ;

export default function ClientCardInputs(props: Props) {
  const dispatch = useDispatch();
  const methods = useForm<ClientInputsInterface>({
    defaultValues: { ...props.inputs },
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const isMobile = useSelector((state: any) => state.app.isMobile);
  const save = (formData: ClientInputsInterface) => {
    dispatch(exerciseCardActions.updateClientInputsCard(props.inputs.card, formData));
  };

  const intervalSave = useCallback(
    debounce(async () => {
      await methods.handleSubmit(save, onError)();
    }, 2000),
    [],
  );

  const onError = (error: any, e: any) => console.log(error);

  useEffect(() => {
    if (methods.formState.isDirty && !methods.formState.isSubmitted) {
      intervalSave();
    }
  }, [methods.formState]);

  useEffect(() => {
    
    methods.reset(props.inputs);
    
  }, [props.inputs]);

  function getInput(
    key: number,
    reg: keyof ClientInputsInterface,
    value: any,
    index: number,
    setInput: number,
  ) {
    let ratingOpt: ReactElement[] = [];
    for (let i = 1; i <= 10; i++) {
      ratingOpt.push(
        <MenuItem className="setInput" key={i} value={i.toString()}>
          {i}
        </MenuItem>,
      );
    }
    let def;
    if (key) {
      def = props.inputOptions[key].input_type;
    }
    switch (def) {
      case input_type.Select:
        return (
          <Controller
            key={reg}
            control={methods.control}
            name={reg}
            defaultValue={props.inputs.inputs[index].value[setInput]}
            render={({ field }: {field: any}) => (
              <StyledSelect
                {...field}
                size="small"
                variant="outlined"
                sx={{ minHeight: '0px' }}
                fullWidth
              >
                {ratingOpt}
              </StyledSelect>
            )}
          />
        );
      default:
        return (
          <Controller
            key={reg}
            control={methods.control}
            name={reg}
            defaultValue={props.inputs.inputs[index].value[setInput]}
            render={({ field }: {field: any}) => (
              <StyledInput
                variant="outlined"
                size="small"
                fullWidth={true}
                {...field}
              />
            )}
          />
        );
    }
  }

  return (
    <div className="cardInputs">
      <div style={styles.divContainer(Object.keys(props.inputs.inputs).length)}>
      <div style={styles.divItem as React.CSSProperties}>
          <div>No.</div>
          {(props.inputs.inputs as InputGroup)[0].value.map((_, index) => (
            <Avatar key={index + "avatar"} sx={styles.avatar}>
              {index + 1}
            </Avatar>
          ))}
        </div>
        {(props.inputs.inputs).map(({key, value}, index) => {
          return (
            <div key={index + "inputs"} style={styles.divItem as React.CSSProperties}>
              { props.inputOptions[key] && (
                <Tooltip disableFocusListener title={props.inputOptions[key].description}>
                  <span style={{display:'flex', alignItems:'center', justifyContent:'space-evenly', height: '100%'} as React.CSSProperties }>{isMobile?props.inputOptions[key].initials :props.inputOptions[key].name} <InfoOutlined/></span>
                </Tooltip>
              )}  
              {value.map((v: any, i: number) => {
                return (
                  getInput(key, `inputs.${index}.value.${i}` as keyof ClientInputsInterface, v, index, i)
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="variation-cont">
        <div className="variation">
          <Typography variant="button" component="span">
            Predicted Max:
          </Typography>
          <Typography variant="body1">
            {props.inputs.predicted_max.one_rm}
          </Typography>
        </div>
        <div  className="variation">
          <Typography variant="button" component="span">
            Factor:
          </Typography>
          <Typography variant="body1">
            {props.inputs.predicted_max.factor}
          </Typography>
        </div>
      </div>
      <div style={{ margin: '15px 7.5px 5px 7.5px', width: 'calc(100% - 15px)' } as React.CSSProperties} >
        <Controller
          control={methods.control}
          name={`comment`}
          render={({ field }: {field: any}) => (
            <StyledInput
              {...field}
              value={field.value ?? ''}
              fullWidth
              label="Comment"
              height="45px"
              textalign='left'
              multiline
            />
          )}
        />
      </div>
    </div>
  );
}
