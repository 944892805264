import React from 'react';
import { Button, Chip, LinearProgress, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Add, Delete, Edit } from '@mui/icons-material';
import { PageActions } from '../../common/PageActions';
import { clientSelectors } from '../../+store/clients/reducers';
import { assessmentActions } from '../../+store/assessments/actionTypes';
import { Assessment, AssessmentResult } from '../../tsUtils/assessmentTypes';
import AssessmentUpdate from './forms/AssessmentUpdate';
import { closeDialogs, openAssessmentResultCreate, openConfirmDialog } from '../../store/app/actions';

const AssessmentStyle = {
  pageStyle: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    padding: '0px 10px',
    height: '100%',
    width: '100%',
  },
  details: {
    display: 'grid',
    gridTemplateColumns:' 1fr',
    gridTemplateRows: 'auto',
    gap: '0.5rem',
  },
  detailsHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: '10px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  resultTableHeader: {
    display: 'table-header-group',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
    backgroundColor: '#f5f5f5',
  },
  resultTableEntry: {
    display: 'table-row',
    cursor: 'pointer',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
    alignItems: 'center',
  },
  tableCell: {
    padding: '10px',
    minWidth: '150px',
    alignContent: 'start',
  },
  clientList: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    paddingLeft: '0px',
  },
  assessmentsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

export default function AssessmentDetailPage() {
  const selectedAssessment = useSelector((state: RootState) => state.assessments.selected_assessment);
  const clients = useSelector((state: RootState) => clientSelectors.selectAll(state));
  const loading = useSelector((state: RootState) => state.assessments.loading);
  const [editting, setEditting] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const saveAssessment = (formData: Assessment) => {
    dispatch(assessmentActions.updateAssessment(formData));
  }

  const saveResult = (formData: AssessmentResult) => {
    return dispatch(assessmentActions.createAssessmentResult(formData))
  };

  const updateResult = (result: AssessmentResult) => {
    return dispatch(assessmentActions.updateAssessmentResult(result));
  }

  const deleteResult = (resultId: number) => {

    return dispatch(openConfirmDialog({
      style: 'DELETE',
      title: "Delete Result",
      message: "Are you sure you want to delete this result?",
      onConfirm: () => dispatch(assessmentActions.deleteAssessmentResult(resultId)),
      onCancel: () => dispatch(closeDialogs())
    }));
  }

  const openResultCreate = (assessmentResult: AssessmentResult) => {
    dispatch(openAssessmentResultCreate({ 
      assessmentResult, 
      saveResult, 
      assessmentName: selectedAssessment?.name, 
      updateResult,
      cancel: () => dispatch(closeDialogs())
     }));
  }

  return (
    <div style={AssessmentStyle.pageStyle as React.CSSProperties} >
      <PageActions
        backButton={{ link: '/coach/assessments', label: "Back" }}
        actionButtons={[
          { action: () => setEditting(!editting), icon: <Edit />, label: "Edit", disabled: false },
        ]}
      />
      {(selectedAssessment && !loading && editting) ||  selectedAssessment?.assessment_config.length == 0 ? (
        <AssessmentUpdate clients={clients} saveAssessment={saveAssessment} selectedAssessment={selectedAssessment} />
      ) : selectedAssessment && !loading && !editting ? (
        <div style={AssessmentStyle.details as React.CSSProperties}>
          <div style={AssessmentStyle.detailsHeader} className='card'>
            <h4 style={{gridColumn: '1/3'}}>Assessment Details</h4>
            <div style={{gridColumn: '1/2'}}>
              <strong>Name:</strong>
              <p>{selectedAssessment.name}</p>
            </div>
            <div style={{gridColumn: '2/3'}}>
              <strong>Category:</strong>
              <p>{selectedAssessment.category}</p>
            </div>
            <div style={{gridColumn: '1/3'}}>
              <strong>Description:</strong>
              <p>{selectedAssessment.description}</p>
            </div>
          </div>
          <div className='card'>
            <h4>Assessees</h4>
            {selectedAssessment.clients?.length === 0 ? <p>No clients assigned</p> :
              <ul style={AssessmentStyle.clientList as React.CSSProperties}>
                {selectedAssessment.clients?.map((client, index) => (
                  <Chip color="info" key={index} label={client?.first_name + " " + client?.last_name} />
                ))}
              </ul>
            }
          </div>
          <div style={{overflowX:'auto'}} className='card'>
            <div style={AssessmentStyle.assessmentsHeader}>
              <h4>Results</h4> 
              <Button 
                color="primary" 
                onClick={
                  () => {
                    const assessmentResult = new AssessmentResult({
                      assessment: selectedAssessment?.id,
                      results: selectedAssessment?.assessment_config,
                      assessment_date: new Date().toISOString(),
                      comment: "",
                    })
                    return openResultCreate(assessmentResult);
                  }
                } >
                <Add /> Add Result
              </Button>
            </div>
            <table style={AssessmentStyle.table as React.CSSProperties} >
              <thead style={AssessmentStyle.resultTableHeader}>
                <tr>
                  <th style={AssessmentStyle.tableCell}><strong>Assessee</strong></th>
                  <th style={AssessmentStyle.tableCell}><strong>Date</strong></th>
                  <th style={AssessmentStyle.tableCell}><strong>Comment</strong></th>
                </tr>
              </thead>
              <tbody>
                {selectedAssessment.results.length === 0 ? (
                  <tr>
                    <td style={AssessmentStyle.tableCell} colSpan={3}><p>No results</p></td>
                  </tr>
                ) : (
                  selectedAssessment.results.map((result, index) => (
                    <tr 
                      style={AssessmentStyle.resultTableEntry} 
                      key={index}
                      onClick={() => openResultCreate(new AssessmentResult({...result, assessor: user?.id, client: result.client.id, assessment: selectedAssessment.id }))}
                    >
                      <td style={AssessmentStyle.tableCell}>{result.client?.first_name} {result.client?.last_name}</td>
                      <td style={AssessmentStyle.tableCell}>{new Date(result.assessment_date).toLocaleDateString()}</td>
                      <td style={AssessmentStyle.tableCell}>{result.comment}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            
          </div>
        </div>
      ) : (
        <LinearProgress sx={{ width: '100%' }} />
      )
      }
    </div>
  );
}