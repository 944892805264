import { put, takeLatest, SagaReturnType, call } from 'redux-saga/effects';
import { updateClientInputsSingle } from '../../clientApp/services/clientInputsApi';
import { saved, error } from '../../store/app/actions';
import * as exerciseCardAPI from '../../coachApp/services/exerciseCardsAPI';
// import { REFRESH_TOKEN_REQUEST, signInFail, signInSuccess, SIGN_IN_REQUEST, SIGN_IN_SUCCESS } from "../../.";
import { actionTypes } from './actionTypes';
import {
  exercise_cardsLoading,
  exercise_cardsFail,
  getExerciseCardsSuccess,
  createExerciseCardSuccess,
  updateExerciseCardSuccess,
  deleteExerciseCardSuccess,
  updateClientInputsSuccess,
  upsertClientInputs,
} from './reducers';
import * as types from './types';
import { ExerciseCardClass, InputGroup } from '../../tsUtils/exerciseCardTypes';
import { SuccessResponse } from '../../tsUtils/responseType';
import { SessionConfig } from '../../tsUtils';
import { updateSessionConfigSuccess } from '../sessions/reducers';

export function* getExerciseCardsSaga({ query }: types.GetExerciseCards) {
  try {
    yield put(exercise_cardsLoading());
    const response: SagaReturnType<typeof exerciseCardAPI.getExerciseCards> =
      yield exerciseCardAPI.getExerciseCards(query);
    yield put(getExerciseCardsSuccess(response));
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(exercise_cardsFail(e));
  }
}
export function* createExerciseCardSaga({ exerciseCard, sectionIndex }: types.CreateExerciseCardRequest) {
  try {
    yield put(exercise_cardsLoading());
    const response: SagaReturnType<typeof exerciseCardAPI.createExerciseCard> =
      yield exerciseCardAPI.createExerciseCard(exerciseCard, sectionIndex);
    if (response.ok) {
      const data: SuccessResponse<{card: ExerciseCardClass, session_config: SessionConfig}> = yield response.json();
      yield put(saved(data.message));
      yield put(createExerciseCardSuccess(data.data!.card!));
      yield put(updateSessionConfigSuccess({id: data.data!.card!.session, config: data.data!.session_config!}));
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(exercise_cardsFail(e));
  }
}
export function* updateExerciseCardSaga({ exerciseCard }: types.UpdateExerciseCardRequest) {
  try {
    yield put(exercise_cardsLoading());
    yield put(updateExerciseCardSuccess(exerciseCard));
    const response: SagaReturnType<typeof exerciseCardAPI.updateExerciseCard> = yield exerciseCardAPI.updateExerciseCard(exerciseCard);
    if (response.ok) {
      const data: SuccessResponse<ExerciseCardClass > = yield response.json();
      yield put(saved(data.message));
      yield put(upsertClientInputs(data.data?.client_inputs));
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(exercise_cardsFail(e));
  }
}
export function* updateClientInputsCardSaga({ cardId, inputs }: types.UpdateClientInputsRequest) {
  try {
    yield put(exercise_cardsLoading());
    yield put(upsertClientInputs([inputs]));
    const response: SagaReturnType<typeof updateClientInputsSingle> = yield call(updateClientInputsSingle, {
      id: inputs.id!,
      inputs: inputs,
      comment: inputs.comment,
    });
    if (response.ok) {
      const data: SuccessResponse<InputGroup> = yield response.json();
      yield put(updateClientInputsSuccess(data.data!));
      yield put(saved(data.message));
    } else {
      throw response;
    }
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(exercise_cardsFail(e));
  }
}
export function* updateExerciseCardExerciseSaga({
  exercise,
  cardId,
}: types.UpdateExerciseCardExerciseRequest) {
  try {
    yield put(exercise_cardsLoading());
    yield put(updateExerciseCardSuccess({ exercise, cardId }));
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(exercise_cardsFail(e));
  }
}

export function* deleteExerciseCardSaga({ id, sectionIndex, sessionId }: types.DeleteExerciseCardRequest) {
  try {
    yield put(exercise_cardsLoading());
    const response: SagaReturnType<typeof exerciseCardAPI.deleteExerciseCard> =
      yield exerciseCardAPI.deleteExerciseCard(id, sectionIndex);
    if (response.ok) {
      const data: SuccessResponse<SessionConfig> = yield response.json();
      yield put(saved(data.message));

      yield put(deleteExerciseCardSuccess(id));
      yield put(updateSessionConfigSuccess({id: sessionId, config: data.data!}));
    }
    
  } catch (e: any) {
    yield console.log(e);
    yield put(error());
    yield put(exercise_cardsFail(e));
  }
}
export function* exerciseCardSaga() {
  yield takeLatest(actionTypes.GET_EXERCISE_CARDS, getExerciseCardsSaga);
  yield takeLatest(actionTypes.CREATE_EXERCISE_CARD_REQUEST, createExerciseCardSaga);
  yield takeLatest(actionTypes.UPDATE_EXERCISE_CARD_REQUEST, updateExerciseCardSaga);
  yield takeLatest(actionTypes.UPDATE_CLIENT_INPUTS_REQUEST, updateClientInputsCardSaga);
  yield takeLatest(actionTypes.DELETE_EXERCISE_CARD_REQUEST, deleteExerciseCardSaga);
  yield takeLatest(
    actionTypes.UPDATE_EXERCISE_CARD_EXERCISE_REQUEST,
    updateExerciseCardExerciseSaga,
  );
  // yield takeLatest(REFRESH_TOKEN_REQUEST, doRefreshToken);
}
