import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { UserBest } from '../../tsUtils';
import { closeDialogs, openAssessmentResultCreate, openConfirmDialog, openSlopeGraphDialog } from '../../store/app/actions';
import { OpenInNew } from '@mui/icons-material';
import { AssessmentResult, AssessmentResultSummaryClient } from '../../tsUtils/assessmentTypes';
import { assessmentActions } from '../../+store/assessments/actionTypes';

export interface ClientProps {

}

const ClientStyle = {
  container: {
    padding: '.5rem',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
  main: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto',
    gap: '.5rem',
  },
  details: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  detailsItem: {
    minWidth: '80px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left',
  },
  tableRow: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
  },
  tableCell: {
    padding: '10px',
    minWidth: '150px',
    alignContent: 'start',
  },
  clickableRow: {
    cursor: 'pointer',
  },
};

export default function Client(props: ClientProps) {
  const client = useSelector((state: RootState) => state.clients.selected_client);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  const dispatch = useDispatch();

  const saveResult = (formData: AssessmentResult) => {
    return dispatch(assessmentActions.createAssessmentResult(formData));
  };

  const updateResult = (result: AssessmentResult) => {
    return dispatch(assessmentActions.updateAssessmentResult(result));
  };

  const openResultCreate = (assessmentResult: AssessmentResultSummaryClient) => {
    dispatch(
      openAssessmentResultCreate({
        assessmentResult: new AssessmentResult({
          ...assessmentResult,
          assessment: assessmentResult.assessment.id,
          client: client?.id,
        }),
        saveResult,
        assessmentName: assessmentResult.assessment.name,
        updateResult,
        cancel: () => dispatch(closeDialogs()),
      })
    );
  };

  const openSlopeCurve = (bests: UserBest) => {
    dispatch(
      openSlopeGraphDialog({
        exercise: bests.exercise.name,
        coef: bests.bests.velocity.coefficients,
        intercept: bests.bests.velocity.intercept,
        isMobile,
        onClose: () => dispatch(closeDialogs()),
      })
    );
  };

  return (
    <div style={ClientStyle.container}>
      <div style={ClientStyle.main}>
        <div className="card">
          <h4>Client Details</h4>
          <div style={ClientStyle.details as React.CSSProperties}>
            <div style={ClientStyle.detailsItem}>
              <strong>Name:</strong>
              <p>
                {client?.first_name} {client?.last_name}
              </p>
            </div>
            <div style={ClientStyle.detailsItem}>
              <strong>Email:</strong>
              <p>{client?.email}</p>
            </div>
            <div style={ClientStyle.detailsItem}>
              <strong>DOB:</strong>
              <p>{client?.metrics.dob ?? '-'}</p>
            </div>
            <div style={ClientStyle.detailsItem}>
              <strong>Height:</strong>
              <p>{client?.metrics.height ?? '-'}</p>
            </div>
            <div style={ClientStyle.detailsItem}>
              <strong>Weight:</strong>
              <p>{client?.metrics.weight ?? '-'}</p>
            </div>
          </div>
        </div>
        <div style={{overflowX: 'auto'}} className="card">
          <h4>Assessment Results</h4>
          <table style={ClientStyle.table as React.CSSProperties}>
            <thead style={ClientStyle.tableHeader as React.CSSProperties}>
              <tr>
                <th style={ClientStyle.tableCell}>Assessment Name</th>
                <th style={ClientStyle.tableCell}>Assessment Date</th>
                <th style={ClientStyle.tableCell}>Assessments</th>
                <th style={ClientStyle.tableCell}>Type</th>
              </tr>
            </thead>
            <tbody>
              {client?.assessment_results.map((assessment, index) => (
                <tr
                  key={index}
                  style={{ ...ClientStyle.tableRow, ...ClientStyle.clickableRow }}
                  onClick={() => openResultCreate(assessment)}
                >
                  <td style={ClientStyle.tableCell}>{assessment.assessment.name}</td>
                  <td style={ClientStyle.tableCell}>
                    {new Date(assessment.assessment_date).toLocaleDateString()}
                  </td>
                  <td style={ClientStyle.tableCell}>
                    {assessment.results.map((result, index) => (
                      <div key={index}>
                        <p>{result.exercise?.name}</p>
                      </div>
                    ))}
                  </td>
                  <td style={ClientStyle.tableCell}>
                    {assessment.results.map((result, index) => (
                      <div key={index}>
                        <p>{result.assessment_type}</p>
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{overflowX: 'auto'}} className="card">
          <h4>Bests</h4>
          <table style={ClientStyle.table as React.CSSProperties}>
            <thead style={ClientStyle.tableHeader as React.CSSProperties}>
              <tr>
                <th style={ClientStyle.tableCell}>Exercise</th>
                <th style={ClientStyle.tableCell}>1RM</th>
                <th style={ClientStyle.tableCell}>MVT</th>
                <th style={ClientStyle.tableCell}>Velocity Curve</th>
              </tr>
            </thead>
            <tbody>
              {client?.bests.map((best, index) => (
                <tr key={index} style={ClientStyle.tableRow}>
                  <td style={ClientStyle.tableCell}>{best.exercise.name}</td>
                  <td style={ClientStyle.tableCell}>{best.bests.one_rm}</td>
                  <td style={ClientStyle.tableCell}>
                    {best.bests.velocity.mvt ? best.bests.velocity.mvt : '-'}
                  </td>
                  <td
                    style={{
                      ...ClientStyle.tableCell,
                      cursor: best.bests.velocity?.intercept ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                      if (best.bests.velocity?.coefficients) {
                        openSlopeCurve(best);
                      }
                    }}
                  >
                    {best.bests.velocity?.coefficients ? (
                      <span>
                        View curve <OpenInNew color="primary" />
                      </span>
                    ) : (
                      'Curve not calculated'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

